/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Typography,
} from 'antd';

import { UploadOutlined } from '@ant-design/icons';

import AppHelper from '../../Library/AppHelper';
import * as Consts from '../../Library/Consts';
import GenUtil from '../../Library/GenUtil';
import {
  ClientPrincipal,
  CRPriceListItem,
  PriceListItem,
  PriceListItemCollection,
} from '../../Library/Models';
import WebApiHelper from '../../Library/WebApiHelper';
import ExcelToJSON from '../../Library/xlsx-helper.js';

const { Title } = Typography;

export interface IImportDataProps {
  curUser: ClientPrincipal;
  updatePriceListItemsCol: (col: PriceListItemCollection) => void;
}

let excelFileCR: any = null;
let csvFileSoftware: any = null;
let csvFileNCE: any = null;
let csvFileEOS: any = null;

const sheetNameCR: string = 'USD';

export const ImportData: React.FunctionComponent<IImportDataProps> = (props: React.PropsWithChildren<IImportDataProps>) => {

  useEffect(() => {
    Consts.DEBUG_LOG_DIDMOUNT && console.log('[ImportData] mounted!');
  }, []);


  const [loading, setLoading] = useState(false);


  const handleFileSelectCR = (evt: any) => {
    var files = evt.target.files;
    if (evt != null && evt.target != null && evt.target.files != null && evt.target.files.length > 0) {
      excelFileCR = files[0];
    }
    else {
      excelFileCR = null;
    }
  }

  const handleFileSelectSoftware = (evt: any) => {
    var files = evt.target.files;
    if (evt != null && evt.target != null && evt.target.files != null && evt.target.files.length > 0) {
      csvFileSoftware = files[0];
    }
    else {
      csvFileSoftware = null;
    }
  }

  const handleFileSelectNCE = (evt: any) => {
    var files = evt.target.files;
    if (evt != null && evt.target != null && evt.target.files != null && evt.target.files.length > 0) {
      csvFileNCE = files[0];
    }
    else {
      csvFileNCE = null;
    }
  }

  const handleFileSelectEos = (evt: any) => {
    var files = evt.target.files;
    if (evt != null && evt.target != null && evt.target.files != null && evt.target.files.length > 0) {
      csvFileEOS = files[0];
    }
    else {
      csvFileEOS = null;
    }
  }

  const unquoteString = (s: string): string => {
    if (s.startsWith("\"")) {
      s = s.substring(1)
    }
    if (s.endsWith("\"")) {
      s = s.slice(0, -1)
    }
    return s
  }

  const getSegment = (s: string): string => {
    if (s === "Education") return "ACADEMIC";
    if (s === "Commercial") return "CORPORATE";
    return s.toUpperCase();
  }

  const getTermDuration = (s: string): string => {
    if (s === "P1M") return "1M";
    if (s === "P1Y") return "1Y";
    if (s === "P3Y") return "3Y";
    return s.toUpperCase();
  }


  const handleSubmit = async () => {
    let fileCR = excelFileCR;

    if (typeof fileCR == 'undefined' || fileCR == null) {
      AppHelper.showMessageWarningBar('CR file not found.');
      return;
    }

    if (typeof csvFileSoftware == 'undefined' || csvFileSoftware == null) {
      AppHelper.showMessageWarningBar('Software file not found.');
      return;
    }

    if (typeof csvFileNCE == 'undefined' || csvFileNCE == null) {
      AppHelper.showMessageWarningBar('New Commerce Experience file not found.');
      return;
    }

    if (typeof csvFileEOS == 'undefined' || csvFileEOS == null) {
      AppHelper.showMessageWarningBar('End of Sale file not found.');
      return;
    }

    setLoading(true);

    const csvDataSoftware = await csvFileSoftware.text();
    const csvRowsSoftware = csvDataSoftware.split(/\r?\n/);

    const csvDataNCE = await csvFileNCE.text();
    const csvRowsNCE = csvDataNCE.split(/\r?\n/);

    const csvDataEOS = await csvFileEOS.text();
    const csvRowsEOS = csvDataEOS.split(/\r?\n/);

    let arCR: any[] = [];
    setTimeout(() => {
      var xl2json = new ExcelToJSON();
      xl2json.parseExcel(fileCR, sheetNameCR, true, (dataCR: any) => {
        arCR = dataCR;
        processImportData(arCR, csvRowsSoftware, csvRowsNCE, csvRowsEOS);
      });
    }, 100);
  }


  const processImportData = (arCR: any[], arSoftware: any[], arNCE: any[], arEOS: any[]) => {
    if (arCR.length === 0) {
      AppHelper.showMessageWarningBar('No CR data found in file.');
      setLoading(false);
      return;
    }

    if (arSoftware.length === 0) {
      AppHelper.showMessageWarningBar('No Software data found in file.');
      setLoading(false);
      return;
    }

    if (arNCE.length === 0) {
      AppHelper.showMessageWarningBar('No New Commerce Experience data found in file.');
      setLoading(false);
      return;
    }

    if (arEOS.length === 0) {
      AppHelper.showMessageWarningBar('No End of Sale data found in file.');
      setLoading(false);
      return;
    }

    let colPriceList: PriceListItem[] = [];
    let i: number = 0;

    try {
      arCR.forEach((el: any[], idx: number) => {
        if (idx === 0) return; // skip first row, will be column headers

        let o: CRPriceListItem = {
          id: ++i,
          material: GenUtil.safeTrim(el[11]).toUpperCase(),
          offerDisplayName: GenUtil.safeTrim(el[3]),
          licenseAgreementType: GenUtil.safeTrim(el[5]).toUpperCase(),
          listPrice: GenUtil.safeToNumber(el[9]),
          erpPrice: GenUtil.safeToNumber(el[10])
        };

        // all CR Items are billed monthly for a 1 Year Term
        colPriceList.push({
          brCost: o.listPrice,
          description: o.offerDisplayName,
          id: o.id,
          licAgrType: o.licenseAgreementType,
          msrp: o.erpPrice,
          productId: o.material,
          source: 'CR',
          billingPlan: 'Monthly',
          termDuration: '1Y'
        });
      });

      arSoftware.forEach((row: string, idx: number) => {

        if (idx === 0) return; // skip first row, will be column headers
        const cells = row.split('",');

        //skip monthly entries
        let billingPlan = unquoteString(cells[8])
        if (billingPlan === "Monthly") {
          return;
        };

        if (billingPlan === "OneTime") {
          billingPlan = "Perpetual"
        }

        const skuTitle = unquoteString(cells[3])
        const termDuration = getTermDuration(unquoteString(cells[7]));

        let brCost = Number(unquoteString(cells[11]));
        let msrp = Number(unquoteString(cells[17]));

        if (termDuration === "3Y" && billingPlan === "Annual") {
          brCost = brCost / 3;
          msrp = msrp / 3;
        }

        colPriceList.push({
          brCost: brCost,
          description: skuTitle,
          id: ++i,
          licAgrType: getSegment(unquoteString(cells[18])),
          msrp: msrp,
          productId: unquoteString(cells[1]),
          billingPlan: billingPlan,
          termDuration: termDuration,
          source: 'SW'
        });
      });

      arNCE.forEach((row: string, idx: number) => {

        if (idx === 0) return; // skip first row, will be column headers
        const cells = row.split('",');

        const skuTitle = unquoteString(cells[4])
        const billingPlan = unquoteString(cells[9]);
        const termDuration = getTermDuration(unquoteString(cells[8]));
        let brCost = Number(unquoteString(cells[12]));
        let msrp = Number(unquoteString(cells[18]));

        if (termDuration === "1Y" && billingPlan === "Monthly") {
          brCost = brCost / 12;
          msrp = msrp / 12;
        }

        colPriceList.push({
          brCost: brCost,
          description: skuTitle,
          id: ++i,
          licAgrType: getSegment(unquoteString(cells[19])),
          msrp: msrp,
          productId: unquoteString(cells[2]),
          billingPlan: billingPlan,
          termDuration: termDuration,
          source: 'NCE'
        });
      });

      arEOS.forEach((row: string, idx: number) => {
        if (idx === 0) return; // skip first row, will be column headers
        const cells = row.split('",');

        let billingPlan = unquoteString(cells[8])
        const termDuration = getTermDuration(unquoteString(cells[7]));

        const skuTitle = unquoteString(cells[3])
        let brCost = Number(unquoteString(cells[11]));
        let msrp = Number(unquoteString(cells[19]));

        if (termDuration === "3Y" && billingPlan === "Annual") {
          brCost = brCost / 3;
          msrp = msrp / 3;
        }

        if (termDuration === "1Y" && billingPlan === "Monthly") {
          brCost = brCost / 12;
          msrp = msrp / 12;
        }

        colPriceList.push({
          brCost: brCost,
          description: skuTitle,
          id: ++i,
          licAgrType: getSegment(unquoteString(cells[20])),
          msrp: msrp,
          productId: unquoteString(cells[1]),
          billingPlan: billingPlan,
          termDuration: termDuration,
          source: 'EOS'
        });
      });
    }
    catch (error) {
      console.error(`data import error;`, error);
      setLoading(false);
      AppHelper.showMessageErrorBar(`Data Import Error, Ensure All Files have not been edited or re-saved with excell; '${error}'`);
      return;
    }

    // last filters, no null/undefined/'' values allowed (0 values are ok for number only)
    let origCount = colPriceList.length;
    colPriceList = colPriceList.filter((o) => {
      return o.brCost != null && typeof o.brCost !== 'undefined' &&
        o.description != null && typeof o.description !== 'undefined' && o.description !== '' &&
        o.licAgrType != null && typeof o.licAgrType !== 'undefined' && o.licAgrType !== '' &&
        o.msrp != null && typeof o.msrp !== 'undefined' &&
        o.productId != null && typeof o.productId !== 'undefined' && o.productId !== '';
    });
    let filteredCount = colPriceList.length;

    // build payload
    let payload: PriceListItemCollection = {
      id: GenUtil.generateGuid(),
      items: colPriceList
    };

    //save payload to cosmosdb using websvc
    setTimeout(() => {
      (async () => {
        let importDataResp = await WebApiHelper.post1(`/api/web-svc?action=COSMOSDBINSERT&c=${Consts.CONTAINER_PRICELISTITEMS}`, payload);

        if (importDataResp.isErr) {
          AppHelper.showMessageErrorBar('ERROR: ' + importDataResp.msg);
        }
        else {
          if (origCount !== filteredCount) {
            AppHelper.showMessageWarningBar(`WARNING: Some data wasn't imported, all cols in all rows cannot be empty/null (# removed: ${(origCount - filteredCount)}).`);
          }
          else {
            AppHelper.showMessageSuccessBar('Success!');
          }
          props.updatePriceListItemsCol(importDataResp.data); // send updated collection back to parent component
        }
        setLoading(false);
      })();
    }, 100);
  }

  return (
    <>

      <div className='wbs25'>

        <Title level={4} className='wtbs25'>Import Price Lists:</Title>

        <div className='wbs25 tip'>{AppHelper.renderDSIH(`
          * Using the file input controls below, Upload the 3 Microsoft Partner Pricing spreadsheets from your computer. Sheet names are important!
          <br/>
          <br/>* 'Cloud-Reseller-Pricelist' (CR) should be an excell file (.xlsx) containing a '${sheetNameCR}' sheet.
          <br/>* Excel files are imported using Column Index, NOT Column Names. So column indexes are very important and cannot change.
          <br/>* Assumed columns indexes for the Excell file (starting at 0): 3-offerName / 5-licAgreement / 9-listPrice / 10-erpPrice / 11-material
          <br/>
          <br/>* 'Software-Pricelist' should be a csv file. It must be extracted from it's .zip file
          <br/>* 'Newcommerce-Cloud-Reseller-Pricelist' should be a csv file. It must be extracted from it's .zip file
          <br/>* CSV files are imported using Column Index, NOT Column Names. So column indexes are very important and cannot change.
          <br/>* Assumed columns for the CSV files (starting at 0): 1-ProductId / 3-SkuTitle / 11-UnitPrice / 17-ERP / 18-Segment
          `)}
        </div>

        <div className='wbs25'>
          <span style={{ fontWeight: 'bold' }}>{`Choose 'Cloud-Reseller-Pricelist' (CR) File:`}</span><br />
          <form encType="multipart/form-data">
            <input type="file" name="files[]" onChange={handleFileSelectCR} />
          </form>
        </div>

        <div className='wbs25'>
          <span style={{ fontWeight: 'bold' }}>{`Choose 'Software-Pricelist' (Software) File:`}</span><br />
          <form encType="multipart/form-data">
            <input type="file" name="files[]" onChange={handleFileSelectSoftware} />
          </form>
        </div>

        <div className='wbs25'>
          <span style={{ fontWeight: 'bold' }}>{`Choose 'Newcommerce-Cloud-Reseller-Pricelist' (New Commerce Experience) File:`}</span><br />
          <form encType="multipart/form-data">
            <input type="file" name="files[]" onChange={handleFileSelectNCE} />
          </form>
        </div>

        <div className='wbs25'>
          <span style={{ fontWeight: 'bold' }}>{`Choose 'End-of-Sale' (End of Sale) File:`}</span><br />
          <form encType="multipart/form-data">
            <input type="file" name="files[]" onChange={handleFileSelectEos} />
          </form>
        </div>

        <div className='wbs25'>
          <Button type='primary' onClick={handleSubmit} disabled={loading} icon={<UploadOutlined />}>Import</Button>
        </div>
      </div>
    </>
  );
};